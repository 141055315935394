const SANDBOX_API_URL = window.PAAZL_CHECKOUT_WIDGET_API_URL;
const SANDBOX_RESOURCE_URL = window.PAAZL_CHECKOUT_WIDGET_RESOURCE_URL;

const VERSION = process.env.VERSION;
const ENV_API_URL = process.env.API_URL;
const ENV_RESOURCE_URL = process.env.RESOURCE_URL;

const DEV_API_URL = "http://localhost:8080/rest-widget/v1";
const DEV_RESOURCE_URL = "http://localhost:3000";


export const BASE_URL = SANDBOX_API_URL || ENV_API_URL || DEV_API_URL;
export const BASE_WIDGET_CONFIG_URL = "/widget-config";

export const OPTION_LIST_URL = "/shippingoptions";
export const SERVICE_POINT_LIST_URL = "/pickuplocations";
export const SAVE_CHECKOUT_INFO_URL = "/checkout";
export const GOOGLE_MAPS_API_KEY_URL = BASE_WIDGET_CONFIG_URL + "/google-maps-api-key";
export const STORE_LOGO_URL = "/store/logo";
export const CARRIER_LOGO_URL ="/carrier/logo/";
export const FINAL_MILE_CARRIER_LOGO_URL ="/final-mile-carrier/logo/";
export const LOGGING_URL ="/logging";

export const RESOURCE_BASE_URL = SANDBOX_RESOURCE_URL || ENV_RESOURCE_URL || DEV_RESOURCE_URL;

export const STORAGE_CONFIG_KEY = 'paazl-previous-config';
export const STORAGE_TAB_KEY = 'paazl-previous-tab';
export const STORAGE_DELIVERY_TYPE_KEY = 'paazl-previous-delivery-type';
export const STORAGE_OPTION_KEY = 'paazl-previous-option';
export const STORAGE_PREFERRED_DATE_KEY = 'paazl-previous-preferred-date';
export const STORAGE_PICKUP_CODE_KEY = 'paazl-previous-pickup-location-code';

export const GIT_VERSION = process.env.GIT_VERSION;
export const GIT_COMMIT_HASH = process.env.GIT_COMMIT_HASH;
export const GIT_BRANCH = process.env.GIT_BRANCH;

export const STYLESHEET_URL =  `${RESOURCE_BASE_URL}/paazl-checkout.min.css?version=${GIT_COMMIT_HASH}`;
export const IMAGES_BASE_URL =  RESOURCE_BASE_URL + "/images/";

export const DECIMAL_COMMA_LOCALE = "NL";
export const DECIMAL_POINT_LOCALE = "EN";

export const SEPARATORS_FORMAT = {
    DECIMAL_POINT: 'DECIMAL_POINT',
    DECIMAL_COMMA: 'DECIMAL_COMMA'
};

export const DELIVERY_FORMATS = {
    DATE: 'DATE',
    NUMBER: 'NUMBER'
};

export const TABS = {
    DELIVERY: "DELIVERY",
    PICKUP: "PICKUP",
    STORE: "STORE",
    PARCEL_LOCKER: "PARCEL_LOCKER"

};

export const TAB_TYPES = {
    TAB: "TAB",
    BUTTON: "BUTTON"
};

export const DELIVERY_TYPES = {
    ESTIMATED_DATE: "ESTIMATED_DATE",
    EXACT_DATE: "EXACT_DATE"
};

export const NETWORK = {
    ALL: "ALL",
    CARRIER: "CARRIER",
    STORE: "STORE"
};

export const STYLES = {
    DEFAULT: "DEFAULT",
    MINIMAL: "MINIMAL",
    GREEN: "GREEN",
    LIGHT_GREEN: "LIGHT_GREEN",
    BROWN: "BROWN",
    BLUE: "BLUE",
    RED: "RED"
};

export const GOOGLE_API_KEY = process.env.GOOGLE_MAP_API_KEY || "AIzaSyD3laAGi3dVttzZu7TOxZt7KNWyubjf1dg"; // default is development key;

export const FORMAT_MASKS = {
    DEFAULT_DATE: "YYYY-MM-DD",
    DAY_NAME: "dddd",
    DAY_MONTH: "DD MMMM",
    TIME: "HH:mm",
    DELIVERY_OPTION_DATE_FORMAT: 'deliveryOptionDateFormat',
    DELIVERY_ESTIMATE_DATE_FORMAT: "deliveryEstimateDateFormat",
    PICKUP_OPTION_DATE_FORMAT: "pickupOptionDateFormat",
    PICKUP_ESTIMATE_DATE_FORMAT: "pickupEstimateDateFormat"
};

export const TO_LOCAL_DATE_FORMATS ={
    deliveryOptionDateFormat: {weekday: 'short', day: 'numeric', month: 'short'},
    deliveryEstimateDateFormat: {weekday: 'long', day: 'numeric', month: 'long'},
    pickupOptionDateFormat: {weekday: 'short', day: 'numeric', month: 'short'},
    pickupEstimateDateFormat: {weekday: 'long', day: 'numeric', month: 'long'}
};

export const METER_IN_DEGREE = 0.0000089;
export const TOOLTIP_TOP_MARGIN_IN_PIXEL = 50;

export const MAP_ZOOMS = [27693, 21282, 16355, 10064, 5540, 2909, 1485, 752, 378, 190, 95, 48, 24, 12, 6, 3, 1.48, 0.74, 0.37, 0.19];

export const CURRENCY_SYMBOLS = {
    ALL:	'Lek',
    AFN:	'؋',
    ARS:	'$',
    AWG:	'ƒ',
    AUD:	'$',
    AZN:	'\u20bc',
    BSD:	'$',
    BBD:	'$',
    BYN:	'Br',
    BZD:	'BZ$',
    BMD:	'$',
    BOB:	'$b',
    BAM:	'KM',
    BWP:	'P',
    BGN:	'лв',
    BRL:	'R$',
    BND:	'$',
    KHR:	'៛',
    CAD:	'$',
    KYD:	'$',
    CLP:	'$',
    CNY:	'¥',
    COP:	'$',
    CRC:	'₡',
    HRK:	'kn',
    CUP:	'₱',
    CZK:	'Kč',
    DKK:	'kr',
    DOP:	'RD$',
    XCD:	'$',
    EGP:	'£',
    SVC:	'$',
    EUR:	'€',
    FKP:	'£',
    FJD:	'$',
    GHS:	'¢',
    GIP:	'£',
    GTQ:	'Q',
    GGP:	'£',
    GYD:	'$',
    HNL:	'L',
    HKD:	'$',
    HUF:	'Ft',
    ISK:	'kr',
    INR:	'₹',
    IDR:	'Rp',
    IRR:	'﷼',
    IMP:	'£',
    ILS:	'₪',
    JMD:	'J$',
    JPY:	'¥',
    JEP:	'£',
    KZT:	'лв',
    KPW:	'₩',
    KRW:	'₩',
    KGS:	'лв',
    LAK:	'₭',
    LBP:	'£',
    LRD:	'$',
    MKD:	'ден',
    MYR:	'RM',
    MUR:	'₨',
    MXN:	'$',
    MNT:	'₮',
    MZN:	'MT',
    NAD:	'$',
    NPR:	'₨',
    ANG:	'ƒ',
    NZD:	'$',
    NIO:	'C$',
    NGN:	'₦',
    NOK:	'kr',
    OMR:	'﷼',
    PKR:	'₨',
    PAB:	'B/.',
    PYG:	'Gs',
    PEN:	'S/.',
    PHP:	'₱',
    PLN:	'zł',
    QAR:	'﷼',
    RON:	'lei',
    RUB:	'₽',
    SHP:	'£',
    SAR:	'﷼',
    RSD:	'Дин.',
    SCR:	'₨',
    SGD:	'$',
    SBD:	'$',
    SOS:	'S',
    ZAR:	'R',
    LKR:	'₨',
    SEK:	'kr',
    CHF:	'CHF',
    SRD:	'$',
    SYP:	'£',
    TWD:	'NT$',
    THB:	'฿',
    TTD:	'TT$',
    TRY:	'₺',
    TVD:	'$',
    UAH:	'₴',
    GBP:	'£',
    USD:	'$',
    UYU:	'$U',
    UZS:	'лв',
    VEF:	'Bs',
    VND:	'₫',
    YER:	'﷼',
    ZWD:	'Z$'
};

export const LOG_LEVEL = {
    NONE: "NONE",
    ERROR: "ERROR",
    DEBUG: "DEBUG",
    TRACE: "TRACE"
};

export const LOG_EVENT = {
     JAVASCRIPT_ERROR: "JAVASCRIPT_ERROR",
     ENDPOINT_ERROR: "ENDPOINT_ERROR",
     LOADING_EVENT: "LOADING_EVENT",
     ENDPOINT_EVENT: "ENDPOINT_EVENT",
     WIDGET_API_EVENT: "WIDGET_API_EVENT"
};



export const DEFAULT_CONFIG = {
    logLevel: LOG_LEVEL.ERROR,

    version: VERSION,

    loadPaazlBasedData: true,
    loadCarrierBasedData: false,
    availableTabs: [TABS.DELIVERY, TABS.PICKUP],
    defaultTab: TABS.DELIVERY,
    headerTabType: TAB_TYPES.TAB,
    shippingOptionsLimit: 3,
    pickupLocationsLimit: 20,
    pickupLocationsPageLimit: 10,
    initialPickupLocations: 3,

    nominatedDateEnabled: false,
    isPricingEnabled: true,
    isShowAsExtraCost: false,
    crossBorderStores: false,
    useCarrierLogoAsMapMarker: false,

    language: "eng",
    deliveryRangeFormat: DELIVERY_FORMATS.DATE,
    separatorsFormat: "DECIMAL_COMMA",

    sortingModel: {
        orderBy: "PRICE",
        sortOrder: "ASC"
    }
};