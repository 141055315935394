import { Component } from 'preact';
import PickupLocationMapForm from "./map/PickupLocationMapForm";
import PickupModalListContainer from "./list/PickupModalListContainer";
import { loadPickupLocations } from "../../service/restApiService";
import TextWrapper from "../../util/wrapper/TextWrapper";
import ClassNames from "classnames";
import SearchContainer from "../../../components/pickup/modal/list/SearchContainer";
import { getPickupType } from "../../../components/util/AppUtils";

export default class PickupModal extends Component {

    constructor(props) {
        super(props);

        const {initialPickupLocations} = props;

        this.state = {
            initialized: initialPickupLocations && initialPickupLocations.length > 0,
            isLoading: false,
            pickupLocationsOnPage: props.config.pickupLocationsPageLimit,
            pickupLocations: initialPickupLocations
        };
    }
    
    componentDidUpdate(previousProps, previousState) {
        const {open, initialPickupLocations, selectedPickupLocationId} = this.props;
        
        let modalOpened = !previousProps.open && open;
        
        if (modalOpened && !previousState.initialized) {
            this.setState({pickupLocations: initialPickupLocations, initialized: true});
        }

        if (modalOpened && selectedPickupLocationId && selectedPickupLocationId !== -1) {
            this.onSelectPickupLocation(selectedPickupLocationId);
        }
    }

    getLocationsPerPage = (pickupLocations) => {
        return pickupLocations && pickupLocations.slice(0, this.state.pickupLocationsOnPage);
    };

    onSelectPickupLocation = (code) => {
        const {pickupLocations} = this.state;

        this.setState({
            pickupLocations: pickupLocations.map(o => {
                return {...o, checked: o.code === code};
            })
        });
    };

    onSelectShippingOption = (code, shippingOptionIdentifier)  => {
        const {pickupLocations} = this.state;

        if (pickupLocations) {
            const itemIndex = pickupLocations.findIndex(o => o.code === code);

            if (itemIndex !== -1) {
                const pickupLocation = pickupLocations[itemIndex];

                this.setState({
                    pickupLocations: [].concat(
                        pickupLocations.slice(0, itemIndex),
                        {...pickupLocation, shippingOptionIdentifier},
                        pickupLocations.slice(itemIndex + 1)
                    )
                });
            }
        }
    };

    closeModal = () => {
        this.props.toggleModal(false);
    };

    limitReached = () => {
        const {pickupLocationsOnPage, pickupLocations} = this.state;
        return !pickupLocations || pickupLocationsOnPage >= pickupLocations.length || pickupLocationsOnPage >= this.props.config.pickupLocationsLimit;
    };

    loadMorePickupLocations = () => {
        const pickupLocationsOnPage = this.state.pickupLocationsOnPage + this.props.config.pickupLocationsPageLimit;
        this.setState({pickupLocationsOnPage});
    };

    loadPickupLocations = (consigneePostalCode) => {
        const {config, isStorePickup, isParcelLocker} = this.props;
        this.setState({isLoading: true});
        loadPickupLocations({...config, consigneePostalCode}, isStorePickup, isParcelLocker, this.setPickupLocations);
    };

    searchByLocation = (southWest, northEast, center) => {
        const {config, isStorePickup, isParcelLocker} = this.props;
        this.setState({isLoading: true});

        const search = {
            rectangle: {
                southWest,
                northEast
            }
        };

        return loadPickupLocations(
            {...config, search},
            isStorePickup,
            isParcelLocker,
            (locations, isLoading) => this.setPickupLocations(locations, isLoading, center));
    };

    setPickupLocations = (pickupLocations, isLoading = false, center) => {
        if (pickupLocations.length > 0) {
            if (!center) {
                pickupLocations[0].checked = true;
            } else {
                let closestElement = this.findClosestElement(pickupLocations, center);
                closestElement.checked = true;
            }
            pickupLocations.forEach((element) => {
                element.shippingOptionIdentifier = element.shippingOptions[0].identifier;
            });
        }

        this.setState({
            pickupLocations,
            pickupLocationsOnPage: this.props.config.pickupLocationsPageLimit,
            isLoading
        });
    };

    findClosestElement = (elements, center) => {
        let closestElement = null;
        let minDistance = Infinity;

        elements.forEach((element) => {
            const distance = this.haversineDistance(element.coordinates, center);
            if (distance < minDistance) {
                minDistance = distance;
                closestElement = element;
            }
        });

        return closestElement;
    };

     haversineDistance = (coords1, coords2) => {
        const toRad = (x) => (x * Math.PI) / 180;

        const lat1 = coords1.latitude;
        const lon1 = coords1.longitude;
        const lat2 = coords2.latitude;
        const lon2 = coords2.longitude;

        const R = 6371; // Radius of the Earth in kilometers
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    };

    createPickupLocationRef = (ref, code) => {
        const {pickupLocations} = this.state;

        if (pickupLocations) {
            const itemIndex = pickupLocations.findIndex(o => o.code === code);

            if (itemIndex !== -1) {
                const pickupLocationsWithRef = [].concat(pickupLocations);
                const pickupLocationWithRef = pickupLocationsWithRef[itemIndex];
                pickupLocationWithRef.ref = ref;

                this.setState({
                    pickupLocations: pickupLocationsWithRef
                });
            }
        }
    };

    preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    render() {
        const {open, onPickupHere, config, isStorePickup, isParcelLocker, storeLogo, initialPickupLocations} = this.props;
        const {isLoading, pickupLocations} = this.state;

        const idPrefix = isStorePickup ? "store-" : "pickup-";
        const pickupType = getPickupType(isStorePickup, isParcelLocker);
        const modalClassName = ClassNames(
            {"pickup-select__modal modal": !isStorePickup},
            {"store-select__modal modal": isStorePickup},
            {open}
        );

        const visiblePickupLocations = this.getLocationsPerPage(pickupLocations);

        return (
            <aside className={modalClassName}
                   aria-modal="true"
                   aria-hidden={!open}
                   id={idPrefix + "select"}
                   onScroll={this.preventScroll}
                   onTouchMove={this.preventScroll}>

                <div className="modal__container">
                    <section className={"window " + idPrefix + "points"} role="dialog" id={isStorePickup ? "store" : "pickup"}>
                        <nav className="window__controls">
                            <header className="window__header">
                                <h3 className="window__title" role="alert" aria-live="polite">
                                    <TextWrapper id={"pickupLocations." + pickupType + ".title"}/>
                                </h3>
                            </header>

                            <SearchContainer loadPickupLocations={this.loadPickupLocations}
                                             config={config}/>

                            <PickupModalListContainer pickupLocations={visiblePickupLocations}
                                                      initialPickupLocations={initialPickupLocations}
                                                      isStorePickup={isStorePickup}
                                                      isParcelLocker={isParcelLocker}
                                                      storeLogo={storeLogo}
                                                      canLoadMore={!isLoading && !this.limitReached()}
                                                      loadMorePickupLocations={this.loadMorePickupLocations}
                                                      onPickupHere={onPickupHere}
                                                      onSelectPickupLocation={this.onSelectPickupLocation}
                                                      onSelectShippingOption={this.onSelectShippingOption}
                                                      createPickupLocationRef={this.createPickupLocationRef}
                                                      config={config}/>
                        </nav>

                        <PickupLocationMapForm pickupLocations={visiblePickupLocations}
                                               isStorePickup={isStorePickup}
                                               storeLogo={storeLogo}
                                               open={open}
                                               isLoading={isLoading}
                                               onSelectPickupLocation={this.onSelectPickupLocation}
                                               searchByLocation={this.searchByLocation}
                                               config = {config}/>

                        <button className="window__close" onClick={this.closeModal}>
                            Close
                        </button>
                    </section>
                </div>
            </aside>
        );
    }
}