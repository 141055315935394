import localeMapping from './localeMapping.json';

import eng from './locales/eng.json';
import nld from './locales/nld.json';
import ger from './locales/ger.json';
import pol from './locales/pol.json';
import rus from './locales/rus.json';
import chi from './locales/chi.json';
import fre from './locales/fre.json';
import ita from './locales/ita.json';
import jpn from './locales/jpn.json';
import spa from './locales/spa.json';
import por from './locales/por.json';


let currentLanguage = JSON.parse(JSON.stringify(eng));

export const I18nResolver = (lang, backendTranslations) => {
    switch (lang) {
        case 'eng':
        case 'en':
            currentLanguage = JSON.parse(JSON.stringify(eng));
            break;
        case 'nld':
        case 'dut':
        case 'nl':
            currentLanguage = JSON.parse(JSON.stringify(nld));
            break;
        case 'ger':
        case 'deu':
        case 'de':
            currentLanguage = JSON.parse(JSON.stringify(ger));
            break;
        case 'pol':
        case 'pl':
            currentLanguage = JSON.parse(JSON.stringify(pol));
            break;
        case 'rus':
        case 'ru':
            currentLanguage = JSON.parse(JSON.stringify(rus));
            break;
        case 'chi':
        case 'zho':
        case 'zh':
            currentLanguage = JSON.parse(JSON.stringify(chi));
            break;
        case 'fre':
        case 'fra':
        case 'fr':
            currentLanguage = JSON.parse(JSON.stringify(fre));
            break;
        case 'ita':
        case 'it':
            currentLanguage = JSON.parse(JSON.stringify(ita));
            break;
        case 'jpn':
        case 'ja':
            currentLanguage = JSON.parse(JSON.stringify(jpn));
            break;
        case 'spa':
        case 'es':
            currentLanguage = JSON.parse(JSON.stringify(spa));
            break;
        case 'por':
        case 'pt':
            currentLanguage = JSON.parse(JSON.stringify(por));
            break;
        default:
            currentLanguage = JSON.parse(JSON.stringify(eng));
            break;
    }

    if (backendTranslations && backendTranslations.length > 0) {
        updateTranslationsWithBackend(backendTranslations);
    }

    return currentLanguage;
};

export const resolveLocale = (lang) => {
    return lang ? localeMapping[lang] : "en-GB";
};

export const getCurrentTranslation = () => {
    return currentLanguage;
};

const updateTranslationsWithBackend = (backendTranslations) => {
    backendTranslations.forEach(translation => {
        switch (translation.translationKey) {
            case 'DELIVERY_TAB_LABEL':
                currentLanguage.tabs.DELIVERY = translation.value;
                break;
            case 'STORE_TAB_LABEL':
                currentLanguage.tabs.STORE = translation.value;
                break;
            case 'PICKUP_TAB_LABEL':
                currentLanguage.tabs.PICKUP = translation.value;
                break;
            case 'LOCKER_TAB_LABEL':
                currentLanguage.tabs.PARCEL_LOCKER = translation.value;
                break;
            case 'DELIVERY_TAB_TITLE':
                currentLanguage.shippingOptions.title = translation.value;
                break;
            case 'STORE_TAB_TITLE':
                currentLanguage.pickupLocations.store.title = translation.value;
                break;
            case 'PICKUP_TAB_TITLE':
                currentLanguage.pickupLocations.carrier.title = translation.value;
                break;
            case 'LOCKER_TAB_TITLE':
                currentLanguage.pickupLocations.locker.title = translation.value;
                break;
            case 'ESTIMATED_DATE_BUTTON':
                currentLanguage.tabs.estimatedDate = translation.value;
                break;
            case 'EXACT_DATE_BUTTON':
                currentLanguage.tabs.exactDate = translation.value;
                break;
            case 'DELIVERY_TAB_FOOTER':
                currentLanguage.shippingOptions.packageWillBeDelivered = translation.value;
                break;
            case 'STORE_TAB_FOOTER':
                currentLanguage.pickupLocations.store.packageWillBeReady = translation.value;
                break;
            case 'PICKUP_TAB_FOOTER':
                currentLanguage.pickupLocations.carrier.packageWillBeReady = translation.value;
                break;
            case 'LOCKER_TAB_FOOTER':
                currentLanguage.pickupLocations.locker.packageWillBeReady = translation.value;
                break;
            case 'BUTTON_FROM_MIN_PRICE':
                currentLanguage.common.fromValue = translation.value;
                break;
            case 'BUTTON_FROM_ZERO_PRICE':
                currentLanguage.common.fromZeroValue = translation.value;
                break;
            case 'CUSTOMER_NUMBER_TAB_TITLE':
                currentLanguage.customerNumber.title = translation.value;
                break;
            case 'CUSTOMER_NUMBER_DHL_DE_TAB_TITLE':
                currentLanguage.customerNumber.dhlTitle = translation.value;
                break;
            case 'CUSTOMER_NUMBER_DESCRIPTION':
                currentLanguage.customerNumber.description = translation.value;
                break;
            case 'CUSTOMER_NUMBER_DHL_DE_DESCRIPTION':
                currentLanguage.customerNumber.dhlDescription = translation.value;
                break;
            case 'CUSTOMER_NUMBER_PICK_POST_DESCRIPTION':
                currentLanguage.customerNumber.pickPostDescription = translation.value;
                break;
            case 'CUSTOMER_NUMBER_MY_POST24_DESCRIPTION':
                currentLanguage.customerNumber.myPost24Description = translation.value;
                break;
            case 'INVALID_CUSTOMER_NUMBER':
                currentLanguage.customerNumber.invalidCustomerNumber = translation.value;
                break;
            case 'INVALID_CUSTOMER_NUMBER_DHL_DE':
                currentLanguage.customerNumber.dhlInvalidCustomerNumber = translation.value;
                break;
        }
    });
};