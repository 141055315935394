import {Component} from 'preact';
import TextWrapper from "../../util/wrapper/TextWrapper";
import StringWrapper from "../../util/wrapper/StringWrapper";

export default class PostNumberHeaderForm extends Component {

    constructor(props) {
        super(props);
    }

    getPostNumberTitle = (selectedOption) => {
        let postNumberTitle;
        if (selectedOption.carrier.name === "DHL_DE") {
            postNumberTitle = "customerNumber.dhlTitle";
        } else {
            postNumberTitle = "customerNumber.title";
        }
        return postNumberTitle;
    };

    getPostNumberTitleStatus = (selectedOption) => {
        if (selectedOption.carrier.name === "DHL_DE") {
            return (
                <span className="postnumber__title--status">
                    <StringWrapper content={" ("}/>
                    <TextWrapper id="customerNumber.required"/>
                    <StringWrapper content={")"}/>
                </span>
            );
        }
        return (
            <span className="postnumber__title--status"/>
        );
    };

    render() {
        const {selectedOption} = this.props;
        let customerNumberDescriptionId;
            if (selectedOption.identifier.startsWith("ASENDIA_PICKPOST")) {
                customerNumberDescriptionId = "customerNumber.pickPostDescription";
            } else if (selectedOption.identifier.startsWith("ASENDIA_MY_POST_24")) {
                customerNumberDescriptionId = "customerNumber.myPost24Description";
            } else if (selectedOption.identifier.startsWith("DHL_DE")) {
                customerNumberDescriptionId = "customerNumber.dhlDescription";
            } else {
                customerNumberDescriptionId = "customerNumber.description";
            }


        return (
            <header className="postnumber__header">
                <h1 className="postnumber__title">
                    <TextWrapper id={this.getPostNumberTitle(selectedOption)}/>
                    {this.getPostNumberTitleStatus(selectedOption)}
                </h1>

                <p className="postnumber__description">
                    <TextWrapper id={customerNumberDescriptionId}/>
                </p>
            </header>
        );
    }
}