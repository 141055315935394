import { Component } from 'preact';
import ClassNames from "classnames";
import TextWrapper from "../util/wrapper/TextWrapper";

export default class TabsDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    onClickDropdown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {open} = this.state;

        if (open) {
            this.setState({
                open: false
            });
        } else {
            this.setState({
                open: true
            });

            document.addEventListener('click', this.closeDropdown);
        }
    };

    closeDropdown = () => {
        this.setState({
            open: false
        });

        document.removeEventListener('click', this.closeDropdown);
    };

    onSelectTab = (tab) => {
        const {onSelectTab} = this.props;

        onSelectTab(tab);
    };


    render() {
        const {tabs, activeTab} = this.props;
        const {open} = this.state;

        const singleOption = tabs && tabs.length <= 1;

        return (
            <div className={ClassNames("header__select dropdown", {"dropdown--disabled": singleOption})} onClick={this.onClickDropdown}>
                <div className="dropdown__source">
                    <TextWrapper id={"tabs." + activeTab}
                                 additionalClassName={"method-select__tab--" + activeTab.toLowerCase()}/>
                </div>

                <div className={ClassNames("dropdown__content", {open})}>
                    {
                        tabs &&
                        tabs.filter(t => t !== activeTab)
                            .map((tab) => {
                                return (
                                    // eslint-disable-next-line react/jsx-no-bind
                                    <div className="dropdown__content__item" onClick={this.onSelectTab.bind(null, tab)}>
                                        <TextWrapper id={"tabs." + tab}
                                                     additionalClassName={"method-select__tab--" + tab.toLowerCase()}/>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}