import { render } from 'preact';
import App from './components/App';
import { IntlProvider } from 'preact-i18n';
import { I18nResolver, resolveLocale } from './i18n/I18nResolver';
import { internationalizeDates, setUpDateFormats } from "./components/util/DateTimeUtils";
import { isInternetExplorer, loadStylesheets } from "./components/util/AppUtils";
import { logApiTriggered, logJsError, logSystemInfo } from "./components/util/Logger";
import { doPost } from "./components/util/NetworkUtils";
import {
    BASE_WIDGET_CONFIG_URL,
    STORAGE_CONFIG_KEY,
    STORAGE_DELIVERY_TYPE_KEY,
    STORAGE_OPTION_KEY,
    STORAGE_PICKUP_CODE_KEY,
    STORAGE_PREFERRED_DATE_KEY,
    STORAGE_TAB_KEY
} from "./components/constants/constants";


let translations;
let configuration;


export const init = (config) => {
    logSystemInfo(config);
    loadStylesheets();
    setUpDateFormats(config);

    config.initializedOn = Date.now();

    if (isInternetExplorer()) {
        import(/* webpackChunkName: "ie-polyfills" */ './ie-polyfills').then(() => {
            _prepareWidget(config);
        });
    } else {
        _prepareWidget(config);
    }
};

const _prepareWidget = (config, triggerMethod) => {
    const {apiKey, language} = config;

    if (triggerMethod) {
        logApiTriggered(config, triggerMethod);
        config.initializedOn = Date.now();
    }

    doPost(BASE_WIDGET_CONFIG_URL, {apiKey, locale: resolveLocale(language)})
        .then(res => {
            translations = I18nResolver(config.language || 'eng', res.data.widgetTranslations);

            _render(config);
        })
        .catch(err => {
            console.error("Could not fetch settings from server", err);

            translations = I18nResolver(config.language || 'eng');

            _render(config);
        });
};

const _render = (config) => {
    interceptUnhandledRejection(config);

    try {
        const rootElement = document.getElementById(config.mountElementId || 'paazl-checkout');

        internationalizeDates(translations);

        configuration = getCachedConfiguration(config);

        render(
            <IntlProvider definition={translations}>
                <App config={configuration}/>
            </IntlProvider>,
            rootElement
        );
    } catch (error) {
        logJsError(configuration, error.stack);
    }
};

const getCachedConfiguration = (config) => {
    const stringConfig = JSON.stringify(config);
    const fromStorage = localStorage.getItem(STORAGE_CONFIG_KEY);

    if (fromStorage === stringConfig) {
        localStorage.setItem(STORAGE_CONFIG_KEY, stringConfig);

        return {
            ...config, previousSelection: {
                previousTab: localStorage.getItem(STORAGE_TAB_KEY),
                previousDeliveryType: localStorage.getItem(STORAGE_DELIVERY_TYPE_KEY),
                previousOption: localStorage.getItem(STORAGE_OPTION_KEY),
                previousPreferredDate: localStorage.getItem(STORAGE_PREFERRED_DATE_KEY),
                previousPickupCode: localStorage.getItem(STORAGE_PICKUP_CODE_KEY)
            }
        };
    }

    localStorage.removeItem(STORAGE_TAB_KEY);
    localStorage.removeItem(STORAGE_DELIVERY_TYPE_KEY);
    localStorage.removeItem(STORAGE_OPTION_KEY);
    localStorage.removeItem(STORAGE_PREFERRED_DATE_KEY);
    localStorage.removeItem(STORAGE_PICKUP_CODE_KEY);

    localStorage.setItem(STORAGE_CONFIG_KEY, stringConfig);
    return config;
};

export const interceptUnhandledRejection = (config) => {
    let unhandledRejectionListener = (promiseRejectionEvent) => {
        if (promiseRejectionEvent.reason.stack &&
            promiseRejectionEvent.reason.stack.indexOf("Request failed") === -1) {
            logJsError(config, promiseRejectionEvent.reason.stack);
            window.removeEventListener("unhandledrejection", unhandledRejectionListener);
        }
    };

    window.addEventListener("unhandledrejection", unhandledRejectionListener);
};

export const updateConfig = (config = {}) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    if (!config || typeof config !== 'object') {
        throw "Config should be an object";
    }

    _prepareWidget({...configuration, ...config}, "updateConfig");
};

export const setLanguage = (language) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, language}, "setLanguage");
};

export const setConsigneeCountryCode = (consigneeCountryCode) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, consigneeCountryCode}, "setConsigneeCountryCode");
};

export const setConsignorCountryCode = (consignorCountryCode) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, consignorCountryCode}, "setConsignorCountryCode");
};

export const setConsigneePostalCode = (consigneePostalCode) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, consigneePostalCode}, "setConsigneePostalCode");
};

export const setConsignorPostalCode = (consignorPostalCode) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, consignorPostalCode}, "setConsignorPostalCode");
};

export const setNumberOfProcessingDays = (numberOfProcessingDays) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, numberOfProcessingDays}, "setNumberOfProcessingDays");
};

export const setTags = (tags) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, tags}, "setTags");
};

export const setDeliveryDateRange = (deliveryDateOptions) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, deliveryDateOptions}, "setDeliveryDateRange");
};

export const setShippingOptionsLimit = (shippingOptionsLimit) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, shippingOptionsLimit}, "setShippingOptionsLimit");
};

export const setShippingOptionsPage = (shippingOptionsPage) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, shippingOptionsPage}, "setShippingOptionsPage");
};

export const setPickupLocationsLimit = (pickupLocationsLimit) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, pickupLocationsLimit}, "setPickupLocationsLimit");
};

export const setPickupLocationsPageLimit = (pickupLocationsPageLimit) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, pickupLocationsPageLimit}, "setPickupLocationsPageLimit");
};

export const setInitialPickupLocations = (initialPickupLocations) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, initialPickupLocations}, "setInitialPickupLocations");
};

export const setSortingModel = (sortingModel) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, sortingModel}, "setSortingModel");
};

export const setShipmentParameters = (shipmentParameters) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, shipmentParameters}, "setShipmentParameters");
};

export const setDomestic = (domestic) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, domestic}, "setDomestic");
};

export const setSearch = (search) => {
    if (!configuration) {
        throw "InvalidState - widget is not initialized";
    }

    _prepareWidget({...configuration, search}, "setSearch");
};
