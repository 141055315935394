import { Component } from "preact";
import ClassNames from "classnames";
import TextWrapper from "../../util/wrapper/TextWrapper";
import { convertDateString, parse } from "../../util/DateTimeUtils";
import {CARRIER_LOGO_URL, DELIVERY_FORMATS, FINAL_MILE_CARRIER_LOGO_URL, FORMAT_MASKS} from "../../constants/constants";
import StringWrapper from "../../util/wrapper/StringWrapper";
import { formatPrice } from "../../util/CurrencyUtils";
import { doGet } from "../../util/NetworkUtils";

export default class OptionRowForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            imageAvailable: true,
            carrierLogoImage: ''
        };
    }

    componentDidMount() {
        this.loadCarrierLogo();
    }

    setInputRef = (node) => {
        this.inputRef = node;
    };

    loadCarrierLogo = () => {
        const {config, option} = this.props;

        const withFinalMileCarrier = !!option.finalMileCarrier;

        let logoUrl = CARRIER_LOGO_URL + option.carrier.name + ".svg";
        if (withFinalMileCarrier) {
            logoUrl = FINAL_MILE_CARRIER_LOGO_URL + option.carrier.name + '/' + option.finalMileCarrier.nameOfLogo;
        }

        doGet(logoUrl, config)
            .then(res => {
                this.setState({
                    carrierLogoImage: !res || !res.data ? "" : `data:image/svg+xml;base64,${res.data}`,
                    imageAvailable: true
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    carrierLogoImage: ""
                });
            });
    };

    getOptionDescription = () => {
        const {option} = this.props;

        return option.description && <StringWrapper Tag="p"
                                                    additionalClassName="option__information"
                                                    content={option.description}
                                                    notranslate/>;
    };

    getPrice = () => {
        const {option, config} = this.props;

        return formatPrice(option.rate, config, "option__radio__price");
    };

    getDeliveryEstimate = () => {
        const {option, exactDate} = this.props;

        const estimatedDeliveryRange = option.estimatedDeliveryRange;
        if (estimatedDeliveryRange && !exactDate) {
            return this.renderEstimatedDeliveryRange(estimatedDeliveryRange);
        }

        if (option.deliveryDates && option.deliveryDates.length) {
            let index = 0;

            if (exactDate) {
                index = option.deliveryDates.findIndex(d => exactDate.getTime() === parse(d.deliveryDate).getTime());
            }

            const deliveryDate = convertDateString(option.deliveryDates[index].deliveryDate, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_OPTION_DATE_FORMAT);

            const start = option.deliveryDates[index].timeRange && option.deliveryDates[index].timeRange.start;
            const end = option.deliveryDates[index].timeRange && option.deliveryDates[index].timeRange.end;

            if (start && end) {
                return exactDate ? `${start} - ${end}` : `${deliveryDate}, ${start} - ${end}`;
            }

            return !exactDate && deliveryDate;
        }
    };

    renderEstimatedDeliveryRange = (estimatedDeliveryRange) => {
        const {config} = this.props;

        if (config.deliveryRangeFormat === DELIVERY_FORMATS.NUMBER
            || !estimatedDeliveryRange.earliestDate || !estimatedDeliveryRange.latestDate) {
            return <TextWrapper id="shippingOptions.deliveryBusinessDays"
                                fields={{
                                    min: estimatedDeliveryRange.min,
                                    max: estimatedDeliveryRange.max
                                }}/>;
        }

        let earliestDate = convertDateString(estimatedDeliveryRange.earliestDate,
            FORMAT_MASKS.DEFAULT_DATE,
            FORMAT_MASKS.DELIVERY_OPTION_DATE_FORMAT);

        let latestDate = convertDateString(estimatedDeliveryRange.latestDate,
            FORMAT_MASKS.DEFAULT_DATE,
            FORMAT_MASKS.DELIVERY_OPTION_DATE_FORMAT);

        return `${earliestDate} - ${latestDate}`;
    };

    onSelectOption = () => {
        const {option, onSelectOption, exactDate} = this.props;

        if (!option.checked) {
            onSelectOption && onSelectOption(option.identifier, exactDate);
        }

        this.inputRef && this.inputRef.focus();
    };

    handleImageError = () => {
        this.setState({
            imageAvailable: false
        });
    };

    onFocusIn = () => {
        this.setState({
            focused: true
        });
    };

    onFocusOut = () => {
        this.setState({
            focused: false
        });
    };

    render() {
        const {option} = this.props;
        const {imageAvailable, carrierLogoImage, focused} = this.state;

        const {checked} = option;

        const deliveryEstimate = this.getDeliveryEstimate();
        const price = this.getPrice();
        const optionDescription = this.getOptionDescription();
        const withFinalMileCarrier = !!option.finalMileCarrier;

        return (
            <li className="options__item" onClick={this.onSelectOption}>
                <article className="option">
                    <div className={ClassNames("option__area", {checked, focused})}>
                        <div className="option__radio paazl-delimiter">
                            <p className="option__radio__title">
                                <input className="option__hidden"
                                       type="radio"
                                       id={"option-" + option.identifier}
                                       name="option"
                                       ref={this.setInputRef}
                                       checked={checked}
                                       onFocus={this.onFocusIn}
                                       onBlur={this.onFocusOut}/>

                                <label htmlFor={"option-" + option.identifier}>
                                    <StringWrapper content={option.name}
                                                   additionalClassName="option__radio__title--strong option__radio__title--name"
                                                   notranslate
                                                   capitalize/>

                                    <StringWrapper content={deliveryEstimate}
                                                   additionalClassName="option__radio__title--default option__radio__title--date"
                                                   notranslate
                                                   capitalize/>
                                </label>
                            </p>

                            {price}

                            <figure className="option__radio__image">
                                {
                                    imageAvailable &&
                                    <img src={carrierLogoImage}
                                         className="option__radio__image__source polyfill-object-fit-contain"
                                         onError={this.handleImageError}
                                         title={withFinalMileCarrier ? option.finalMileCarrier.nameOfCarrier : option.carrier.description}
                                         alt={withFinalMileCarrier ? option.finalMileCarrier.nameOfCarrier : option.carrier.description}
                                    />
                                }
                            </figure>
                        </div>

                        {
                            checked &&
                            <div className="paazl-delimiter">
                                <section className="option__extra">
                                    {optionDescription}
                                </section>
                            </div>
                        }
                    </div>
                </article>
            </li>
        );
    }

}