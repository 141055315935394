import {
    convertDateString,
    EXACT_DATE_CONTEXT,
    format,
    formatMinMaxDays,
    FROM_DATE_CONTEXT,
    getDayName,
    parse,
    TO_DATE_CONTEXT
} from "./../util/DateTimeUtils";
import {DELIVERY_FORMATS, FORMAT_MASKS} from "../constants/constants";
import TextWrapper from "./../util/wrapper/TextWrapper";

export const TYPE = {
    DELIVERY_LONG: "DELIVERY_LONG",
    PICKUP_SHORT: "PICKUP_SHORT",
    PICKUP_LONG: "PICKUP_LONG"
};

export const findMinDate = (shippingOptions, deliveryRangeFormat) => {
    const dates = shippingOptions &&
        shippingOptions.map(o => getKnownDate(null, o))
            .filter(d => d)
            .map(e => parse(e))
            .sort((a, b) => a - b);

    if (dates && dates.length) {
        return getDayName(dates[0]);
    }

    const approximateDates = shippingOptions && shippingOptions.map(getApproximateDates).filter(d => d);

    if (deliveryRangeFormat === DELIVERY_FORMATS.DATE) {
        const earliestDates = approximateDates &&
            approximateDates.map(e => e.earliestDate)
                .filter(d => d)
                .map(e => parse(e))
                .sort((a, b) => a - b);

        if (earliestDates && earliestDates.length) {
            return getDayName(earliestDates[0]);
        }
    }

    const approximateDays = approximateDates &&
        approximateDates.filter(e => e.min && e.max)
            .sort((a, b) => a.min - b.min);

    if (approximateDays && approximateDays.length) {
        return formatMinMaxDays(approximateDates[0]);
    }
};

export const resolveDateEstimationComponent = (type, shippingOption, deliveryRangeFormat, language) => {
    const day = getKnownDate(type, shippingOption);
    const startTime = getTimeFrom(shippingOption);
    const endTime = getTimeTo(shippingOption);

    if (day && !startTime && !endTime) {
        return getExactDateLabel(type, day, language);
    } else if (day) {
        return resolveExactDateWithTimeframe(type, day, startTime, endTime, language);
    }

    const approximateDates = getApproximateDates(shippingOption);
    if (approximateDates) {
        return resolveApproximateDateTime(type, deliveryRangeFormat, approximateDates, startTime, endTime);
    }
};

const resolveExactDateWithTimeframe = (type, day, startTime, endTime, language) => {
    if (startTime && !endTime) {
        return getExactDateLabelAfterTime(type, day, startTime, language);
    } else if (!startTime && endTime) {
        return getExactDateLabelBeforeTime(type, day, endTime, language);
    }

    return getExactDateLabelBetweenTime(type, day, startTime, endTime, language);
};

const resolveApproximateDateTime = (type, deliveryRangeFormat, approximateDates, startTime, endTime) => {
    if (deliveryRangeFormat === DELIVERY_FORMATS.NUMBER || !approximateDates.earliestDate || !approximateDates.latestDate) {
        return resolveApproximateDays(type, approximateDates, startTime, endTime);
    }

    return resolveApproximateDates(type, approximateDates, startTime, endTime);

};

const resolveApproximateDays = (type, approximateDates, startTime, endTime) => {
    const {min, max} = approximateDates;

    if (!startTime && endTime) {
        return getDaysRangeLabelBeforeTime(type, min, max, endTime);
    } else if (startTime && !endTime) {
        return getDaysRangeLabelAfterTime(type, min, max, startTime);
    } else if (startTime && endTime) {
        return getDaysRangeLabelBetweenTime(type, min, max, startTime, endTime);
    }

    return getDaysRangeLabel(type, min, max);
};

const resolveApproximateDates = (type, approximateDates, startTime, endTime) => {
    const {earliestDate, latestDate} = approximateDates;

    if (!startTime && endTime) {
        return getApproximateDatesLabelBeforeTime(type, earliestDate, latestDate, endTime);
    } else if (startTime && !endTime) {
        return getApproximateDatesLabelAfterTime(type, earliestDate, latestDate, startTime);
    } else if (startTime && endTime) {
        return getApproximateDatesLabelBetweenTime(type, earliestDate, latestDate, startTime, endTime);
    }

    return getApproximateDatesLabel(type, earliestDate, latestDate);
};


// ------------------- Date resolvers -------------------

const getKnownDate = (type, shippingOption) => {
    const isDelivery = type === TYPE.DELIVERY_LONG;

    if (isDelivery && shippingOption && shippingOption.exactDay) {
        return format(shippingOption.exactDay, FORMAT_MASKS.DEFAULT_DATE, true);
    }

    if (shippingOption && shippingOption.deliveryDates && shippingOption.deliveryDates.length > 0) {
        return shippingOption.deliveryDates[0].deliveryDate;
    }
};

const getApproximateDates = (shippingOption) => {
    return shippingOption && shippingOption.estimatedDeliveryRange;
};

const getTimeFrom = (shippingOption) => {
    const timeRangeStart = shippingOption &&
        shippingOption.deliveryDates &&
        shippingOption.deliveryDates.length &&
        shippingOption.deliveryDates[0].timeRange &&
        shippingOption.deliveryDates[0].timeRange.start;

    const deliveryWindowStart = shippingOption && shippingOption.deliveryWindow && shippingOption.deliveryWindow.start;

    if (timeRangeStart) {
        return timeRangeStart;
    } else if (deliveryWindowStart) {
        return deliveryWindowStart;
    }
};

const getTimeTo = (shippingOption) => {
    const timeRangeEnd = shippingOption &&
        shippingOption.deliveryDates &&
        shippingOption.deliveryDates.length &&
        shippingOption.deliveryDates[0].timeRange &&
        shippingOption.deliveryDates[0].timeRange.end;

    const deliveryWindowEnd = shippingOption && shippingOption.deliveryWindow && shippingOption.deliveryWindow.end;

    if (timeRangeEnd) {
        return timeRangeEnd;
    } else if (deliveryWindowEnd) {
        return deliveryWindowEnd;
    }
};


// ------------------- Known dates labels -------------------

const getExactDateLabel = (type, date, language) => {
    let day = formatDayBasedOnType(type, date);
    day = formatDayBasedOnLanguage(day, language);

    return <TextWrapper id="common.estimates.onDate"
                        fields={{day}}/>;
};


const getExactDateLabelBeforeTime = (type, date, endTime, language) => {
    let day = formatDayBasedOnType(type, date);
    day = formatDayBasedOnLanguage(day, language);

    return <TextWrapper id="common.estimates.onDateBeforeTime"
                        fields={{day, endTime}}/>;
};

const getExactDateLabelAfterTime = (type, date, startTime, language) => {
    let day;

    if (type === TYPE.PICKUP_SHORT) {
        day = date && convertDateString(date, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, EXACT_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_LONG) {
        day = date && convertDateString(date, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, EXACT_DATE_CONTEXT);
    }

    day = formatDayBasedOnLanguage(day, language);

    return <TextWrapper id="common.estimates.onDateAfterTime"
                        fields={{day, startTime}}/>;
};

const getExactDateLabelBetweenTime = (type, date, startTime, endTime, language) => {
    let day;

    day = date && convertDateString(date, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, EXACT_DATE_CONTEXT);

    day = formatDayBasedOnLanguage(day, language);

    return <TextWrapper id="common.estimates.onDateBetweenTime"
                        fields={{day, startTime, endTime}}/>;
};


function formatDayBasedOnType(type, date) {
    let day;

    if (type === TYPE.DELIVERY_LONG) {
        day = date && convertDateString(date, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, EXACT_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_SHORT) {
        day = date && convertDateString(date, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, EXACT_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_LONG) {
        day = date && convertDateString(date, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, EXACT_DATE_CONTEXT);
    }
    return day;
}

export const formatDayBasedOnLanguage = (day, language) => {
    if (language !== null && language === 'nld') {
        let dayStrong = `<strong class="footer__message--strong">${day}</strong>`;

        // If the day is not 'morgen' (tomorrow), prefix it with 'op ' for proper Dutch grammar
        if (day !== null && day.toLowerCase() !== 'morgen') {
            dayStrong = `op ${dayStrong}`;
        }

        return dayStrong;
    }

    return day;
};

// ------------------- Min/max range labels -------------------

const getDaysRangeLabel = (type, min, max) => {
    return <TextWrapper id="common.estimates.withinBusinessDays"
                        fields={{min, max}}/>;
};

const getDaysRangeLabelBeforeTime = (type, min, max, endTime) => {
    return <TextWrapper id="common.estimates.withinBusinessDaysBeforeTime"
                        fields={{min, max, endTime}}/>;
};

const getDaysRangeLabelAfterTime = (type, min, max, startTime) => {
    return <TextWrapper id="common.estimates.withinBusinessDaysAfterTime"
                        fields={{min, max, startTime}}/>;
};

const getDaysRangeLabelBetweenTime = (type, min, max, startTime, endTime) => {
    return <TextWrapper id="common.estimates.withinBusinessDaysBetweenTime"
                        fields={{min, max, startTime, endTime}}/>;
};


// ------------------- Approximate date range labels -------------------


const getApproximateDatesLabel = (type, earliest, latest) => {
    let earliestDate;
    let latestDate;

    if (type === TYPE.DELIVERY_LONG) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_SHORT) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_LONG) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    return <TextWrapper id="common.estimates.betweenDates"
                        fields={{earliestDate, latestDate}}/>;
};


const getApproximateDatesLabelBeforeTime = (type, earliest, latest, endTime) => {
    let earliestDate;
    let latestDate;

    if (type === TYPE.DELIVERY_LONG) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    return <TextWrapper id="common.estimates.betweenDateBeforeTime"
                        fields={{earliestDate, latestDate, endTime}}/>;
};

const getApproximateDatesLabelAfterTime = (type, earliest, latest, startTime) => {
    let earliestDate;
    let latestDate;

    if (type === TYPE.PICKUP_SHORT) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_LONG) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    return <TextWrapper id="common.estimates.betweenDatesAfterTime"
                        fields={{earliestDate, latestDate, startTime}}/>;
};


const getApproximateDatesLabelBetweenTime = (type, earliest, latest, startTime, endTime) => {
    let earliestDate;
    let latestDate;

    if (type === TYPE.DELIVERY_LONG) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_SHORT) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_OPTION_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    if (type === TYPE.PICKUP_LONG) {
        earliestDate = earliest && convertDateString(earliest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, FROM_DATE_CONTEXT);
        latestDate = latest && convertDateString(latest, FORMAT_MASKS.DEFAULT_DATE, FORMAT_MASKS.PICKUP_ESTIMATE_DATE_FORMAT, TO_DATE_CONTEXT);
    }

    return <TextWrapper id="common.estimates.betweenDatesBetweenTime"
                        fields={{earliestDate, latestDate, startTime, endTime}}/>;
};
